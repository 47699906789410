/*Account Summary and first bar containers below ... */
.acctSummary{
    border: 2px solid #5a1db5;
    padding: 10px;
    background: #f7f7f7;
  }
.acct-container{
    max-width: 1000px;
    padding: 0;
    margin: 5px auto;
  }
.acctSummary * {
    background: none;
}