/* greater than 600 px */
/*Navbar CSS Below... */
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
}
.navbar h1 {
  color: #5a1db5;
}
.navbar .links, .linksL {
  margin-left: auto;
  margin-right: 10px;
  height: auto;
}
.navbar a {
  margin-left: 10px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #5a1db5;
}
.nav-icon {
  height: 30px;
  width: 30px;
}
.nav-icon:hover {
  fill: #5a1db5;
}
.logged-in-button {
  height: 30px;
  width: 30px;
  fill: #588515;
}
.logged-out-button {
  height: 30px;
  width: 30px;
  fill: #8b0000;
}
.footer {
  background: #5a1db5;
  border-radius: 0;
  min-height: 100px;
}
.footer * {
  background: none;
}
.footer-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
}
.footer a {
  text-decoration: none;
  color: #fafafa;
  font-size: 16px;
  font-weight: bold;
}
.footer ul {
  list-style-type: none;
}
.footer li {
  padding: 3px;
}
/* Less than 600px  */
@media (max-width: 600px) {
  .navbar{
    display: grid;
    grid-template-columns: 100%;
    padding: 5px;
  }
  .navbar h1 {
    flex-grow: 1;
  }
  .navbar a {
    flex-grow: 1;
    margin: 0;
    padding: 0px;
    flex-wrap: wrap;
    text-align: center;
  }
  .links {
    display: grid;
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: 1fr 1fr 1fr;
    margin: 3px auto;
    row-gap: 2px;
    column-gap: 2px;
    width: 100%;
  }
  /* .links a {
    border: solid 1px #ededed;
    flex-grow: 1;
  } */
  .linksL {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 3px auto;
      row-gap: 2px;
      column-gap: 2px;
      width: 100%;
    }
}
  