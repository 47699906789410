@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
* {
  font-family: "Quicksand";
  margin: 0 5px;
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  margin: auto;
  text-align: center;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 22px;
}
p {
  font-size: 18px;
}
.App {
  background: none;
  padding: 0 0px;
}
.content {
  padding: 0 5px;
  min-height: 1000px;
}
.home {
  display: flex;
}
.home button {
  margin-top: 15px;
  padding: 5px;
  background: #5a1db5;
  border: none;
}
.home button h4 {
  background: none;
  color: #fff;
}
.tile-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  flex-grow: 1;
}
.notfound {
  margin-top: 10px;
}
@media (max-width: 500px) {
  .tile-container {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

}