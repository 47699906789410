.create {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 0px 10px;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #5a1db5;
  margin-bottom: 30px;
}
.create input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create button {
  background: #5a1db5;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
}
.checkbox-list-item {
  margin: 2px 3px;
  flex-grow: 1;
  min-width: 20%;
  border: solid 2px #0bb531;
  background: #0bb531;;
}
.checkbox-list-item input {
  display: none;
}
.checkbox-list-item input[type="checkbox"]:checked ~div{
  background: none;
}
.checkbox-list-item label {
  text-align: center;
  background: none;
  padding: 5px;
}
.category-definition {
  color: #aaa;
}

  
@media (max-width: 500px) {
  .checkbox-list {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
}